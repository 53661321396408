import {useCallback, useMemo, useState, useEffect} from 'react'
import SearchForm from '../components/SearchForm'
import ButtonSelect, {OptionButton} from '../components/ButtonSelect'
import JobSummary, {JobDetail} from '../components/JobSummary'
import {ReactComponent as ExpandIcon} from 'assets/images/expand.svg'
import {ReactComponent as CollapseIcon} from 'assets/images/collapse.svg'
import Collapse from '../components/Collapse'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Link from '../components/Link'
import {useJobRepository} from '../respositories/JobRepository'
import Loader, {PageLoader} from '../components/Loader'
import {useSearchHistory} from '../components/SearchHistory'
import {isEqualWith, kebabCase, take, omitBy} from 'lodash-es'
import {looseCustomizer} from '../utils/equal_customizers'
import {handleFetchError} from '../utils/api'
import TeReo from '../components/TeReo'
import Meta from '../components/Meta'
import {LimitedResultsHelpText, NoResultsHelpText} from '../components/ResultsHelpText'
import {useLocationRepository} from '../respositories/LocationRepository'
import NotFound from './error/NotFound'
import {useSearchParams} from '../utils/SearchParams'
import compareJobsBy from '../utils/sortJobs'
import {count, isBlank} from '../utils/presence'
import {locationName} from '../utils/locations'
import {ReactComponent as BackToTopIcon} from 'assets/images/back-to-top.svg'
import clsx from 'clsx'
import Button from '../components/Button'
import {trackValidationError, usePageSizeTracking, useSearchTracking} from '../utils/tracking'
import {useJobCategoryRepository} from '../respositories/JobCategoryRepository'
import {jobCategoryName} from '../utils/jobCategories'
import RequiresService from '../components/RequiresService'

const SearchResults = (props) => {
  const navigate = useNavigate()
  const routeLocation = useLocation()

  const {locations} = useLocationRepository()
  const {jobCategories} = useJobCategoryRepository()
  const [searchParams, setSearchParams] = useSearchParams(props)
  const {keywords, locationId, categoryId, sort = keywords ? 'relevance' : '-createdAt', workType} = searchParams
  const [, pushSearchHistory] = useSearchHistory()
  const {jobs, actions, loading, error, request, meta, pageSize} = useJobRepository()
  const {incomplete = false, count: jobCount} = meta || {}
  const showMoreLoading = pageSize > jobs.length && jobCount > jobs.length

  const [searchExpanded, setSearchExpanded] = useState(false)
  const toggleSearchExpanded = useCallback(() => setSearchExpanded(prev => !prev), [])

  useSearchTracking({searchParams, loading, error})
  usePageSizeTracking({pageSize, count: jobCount, incomplete})
  useEffect(() => {
    if (error) {
      trackValidationError({resource: 'jobs', action: 'search', error})
    }
  }, [error])

  useEffect(() => {
    // expand search criteria if it has an error
    if (error) {
      setSearchExpanded(true)
    }
  }, [error])

  // useCallback returns the same instance of the handleQueryStateChange function unless setQueryState changes
  const handleSetQueryState = useCallback((event) => {
    setSearchParams({[event.target.name]: event.target.value})
  }, [setSearchParams])

  const [searchState, setSearchState] = useState(searchParams)
  useEffect(() => {
    if (!isEqualWith(searchState, searchParams, looseCustomizer)) {
      actions.clear('error')
    }
  }, [actions, searchState, searchParams])
  useEffect(() => {
    // update searchState to match searchQuery (from the query string) when it changes
    setSearchState({...searchParams})
  }, [searchParams])

  const fetchData = useCallback((requestParams) => {
    actions.index(requestParams).then(() => {
      pushSearchHistory(requestParams)
    }).catch(handleFetchError({only: 'INVALID'}))
  }, [actions, pushSearchHistory])

  useEffect(() => {
    const {sort, ...searchFilters} = searchParams
    const {sort: _, ...requestFilters} = request || {}
    // perform search if search filters have changed since last search
    if (!isEqualWith(omitBy(searchFilters, isBlank), omitBy(requestFilters, isBlank), looseCustomizer)) {
      fetchData(searchParams)
    }
  }, [searchParams, request, fetchData, loading])

  // useCallback returns the same instance of the search function unless setQueryState changes
  const search = useCallback((data) => {
    fetchData(data)
    setSearchParams(data)
    setSearchExpanded(false)
  }, [fetchData, setSearchParams])

  const handleJobClick = job => () => {
    // set job id in history state so it gets refocused on back
    navigate(routeLocation, {replace: true, state: {focus: `#job-${job.id}`}})
  }

  const {showMore} = actions
  const viewAll = useCallback(() => {
    showMore({all: true})
  }, [showMore])

  const jobCountText = useMemo(() => {
    const countText = `${jobCount || 'No'}${incomplete ? '+' : ''}`
    return `${countText} ${jobCount === 1 ? 'job' : 'jobs'} found`
  }, [jobCount, incomplete])

  const getLocationName = locationName(locations)
  const getCategoryName = jobCategoryName(jobCategories)
  let title = 'Search Results'
  if (!keywords && (count(locationId) + count(categoryId)) === 1) {
    if (count(locationId) === 1) {
      title = `Jobs in ${getLocationName(locationId)}`
    } else if (count(categoryId) === 1) {
      title = `${getCategoryName(categoryId)} Jobs`
    }
  }

  return (
    <RequiresService error={error}>
      <div className="mb-lg-5">
        <Meta
          title={title}
          /* eslint-disable-next-line max-len */
          description="Work and Income can help you find a job. Search for full time and part time jobs around New Zealand."
        />

        <div className="container-fluid py-3 px-4 px-lg-5">
          <h1 className="text-md-center m-0 pt-2 pt-md-3">
            <TeReo className="d-block d-sm-inline">Ngā Hua Kimitanga</TeReo> Search Results
          </h1>
        </div>
        <div className="bg-light py-2 d-print-none">
          <div className="container-lg p-4 px-lg-5 px-xl-4 pt-3 pt-sm-4">
            <div className="row g-0 d-xl-none">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
                jsx-a11y/no-noninteractive-element-interactions */}
              <h2 className="col ff-base fw-normal text-dark mb-0 cursor-pointer" onClick={toggleSearchExpanded}>
                New Search
              </h2>
              <Button className="col flex-grow-0 p-0 align-self-center d-flex border-0 text-primary text-hover-dark"
                      aria-expanded={searchExpanded} aria-controls="search-form"
                      onClick={toggleSearchExpanded}>
                {searchExpanded ? <CollapseIcon title="Collapse" /> : <ExpandIcon title="Expand" />}
              </Button>
            </div>
            <Collapse className="mx-n1" id="search-from" expanded={searchExpanded} expandedXl>
              <SearchForm className="pt-3 pt-xl-0 px-1" loading={loading}
                          onSubmit={search} data={searchState} onChange={setSearchState} errors={error?.meta} />
            </Collapse>
          </div>
          <div className="container-lg max-width-xl pb-3 px-4 px-lg-5">
            <ButtonSelect name="workType" label="Filter by work type" value={workType} onChange={handleSetQueryState}>
              <OptionButton value="">All types</OptionButton>
              <OptionButton value="full_time">Full time</OptionButton>
              <OptionButton value="part_time">Part time</OptionButton>
            </ButtonSelect>
          </div>
        </div>
        <div className="container-lg max-width-xl py-3 py-sm-4 px-4 px-lg-5">
          <div className="row pb-1">
            <div className="col-12 col-sm-6 col-md-8 mb-2 mb-sm-0 align-self-end">
              {loading ? (
                <Loader className="pb-3" />
              ) : (
                <span className="h3">{jobCountText}</span>
              )}
            </div>
            {jobCount !== undefined && (
              <div className="col-12 col-sm-6 col-md-4 pb-1 pb-sm-0 d-print-none">
                <label className="form-label fs-5" htmlFor="sort">
                  <span className="text-primary fw-bold">Sort By</span>
                </label>
                <select className="form-select" id="sort" name="sort" value={sort} onChange={handleSetQueryState}>
                  {searchParams.keywords
                    && <option value="relevance">Relevance</option>}
                  <option value="-createdAt">Date listed: newest to oldest</option>
                  <option value="createdAt">Date listed: oldest to newest</option>
                </select>
              </div>
            )}
          </div>
        </div>
        {jobCount !== undefined && (
          <>
            <div id="search-results" className="container-lg max-width-xl px-0 px-sm-4 px-lg-5 pb-4 pb-sm-5">
              {!loading && !jobCount && (
                <NoResultsHelpText />
              )}
              {!loading && incomplete && sort === 'createdAt' && (
                <LimitedResultsHelpText
                  jobCount={jobCount}
                />
              )}
              {take(jobs.sort(compareJobsBy(sort, searchParams)), pageSize).map(job => (
                <JobSummary component={Link} to={`/jobs/${job.id}`} key={job.id} trackingName="View Job"
                            title={job.jobTitle} id={job.id} date={job.createdAt} onClick={handleJobClick(job)}>
                  <JobDetail title={job.jobCategory?.name} />
                  <JobDetail title={[job.region?.name, job.area?.name].filter(l => l).join(' > ')} />
                  <JobDetail title="About the role">
                    {job.jobDescription}
                  </JobDetail>
                  <JobDetail title="Job Requirements">
                    {job.prerequisitesDetails}
                  </JobDetail>
                </JobSummary>
              ))}
              {incomplete && sort !== 'createdAt' && pageSize >= jobCount && !showMoreLoading
                && <LimitedResultsHelpText jobCount={jobCount} />}
            </div>
            {jobCount > 1 && (
              <div className="container-lg max-width-xl px-2 px-sm-4 px-lg-5 pb-4 pb-sm-5">
                <div className="row g-0">
                  <div className="col-12 col-sm-4 fw-bold text-center text-sm-start px-1 pb-3 pb-sm-0">
                    Showing {Math.min(pageSize, jobs.length)} of {jobCount}
                  </div>
                  <div className="col-12 col-sm-4 d-print-none">
                    {(pageSize < jobCount || showMoreLoading) && (
                      <div className="pb-3 pb-sm-0 d-flex flex-column align-items-center">
                        <Button
                          rounded
                          color="primary"
                          onClick={showMore}
                          disabled={showMoreLoading}
                          className={clsx('px-4 mb-1', showMoreLoading && 'btn-loading')}
                        >
                          Show more
                        </Button>
                        <Button link className="fw-bold" onClick={viewAll}
                                disabled={showMoreLoading}>
                          View all
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-sm-4 text-center text-sm-end d-print-none">
                    <a href="#main-content" className="btn-link fw-bold focus-indicator p-1 rounded-1 ms-n2">
                      <BackToTopIcon className="me-2" />Back to top
                    </a>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </RequiresService>
  )
}

const nameMatchesPath = path => item => kebabCase(item.name) === path

const withLocationIdFromPath = Component => function ComponentWithLocationFromPath(props) {
  const {locations, loading} = useLocationRepository()
  const {regionPath, areaPath} = useParams()

  const region = regionPath && locations.find(nameMatchesPath(regionPath))
  const location = areaPath ? region?.areas?.find(nameMatchesPath(areaPath)) : region

  if (regionPath) {
    if (loading) {
      return <PageLoader />
    }
    if (!location) {
      return <NotFound />
    }
  }

  return <Component {...props} locationId={location?.id} />
}

const withCategoryIdFromPath = Component => function ComponentWithCategoryFromPath(props) {
  const {jobCategories, loading} = useJobCategoryRepository()
  const {parentCategoryPath, subCategoryPath} = useParams()

  const parentCategory = parentCategoryPath && jobCategories.find(nameMatchesPath(parentCategoryPath))
  const category = subCategoryPath
    ? parentCategory?.subCategories?.find(nameMatchesPath(subCategoryPath))
    : parentCategory

  if (parentCategoryPath) {
    if (loading) {
      return <PageLoader />
    }
    if (!category) {
      return <NotFound />
    }
  }

  return <Component {...props} categoryId={category?.id} />
}

export default withLocationIdFromPath(withCategoryIdFromPath(SearchResults))
