import dayjs from 'dayjs'
import {deburr} from 'lodash-es'

// a job is more relevant if the match to keywords is in the jobTitle than in jobCategory than in jobDescription
// further, a job is more relevant if the field is an exact match than if contains an exact match, than matches
// one or more words:
const relevanceScore = (jobListingString, keywords) => {
  const normalizedKeywords = deburr(keywords).replace(/[^0-9A-Za-z]+/g, ' ').trim()
  // these words shouldn't contribute to the relevance score so we ignore them
  const ignoreWords = ['an', 'and', 'for', 'in', 'is', 'of', 'on', 'or', 'the']
  // separate out the relevant words from keywords, e.g. 'a ruby on rails' => ['ruby', 'rails']
  const individualKeywords = normalizedKeywords.split(/\s+/).filter(word => (
    word.length > 1 && !ignoreWords.includes(word)
  ))
  const isExactMatch = jobListingString => jobListingString?.toLowerCase() === keywords.toLowerCase()
  const containsExactMatch = jobListingString => (new RegExp(`\\b${keywords}`, 'i').test(jobListingString))
  const matchingWordCount = jobListingString => (
    individualKeywords.filter(word => (new RegExp(`\\b${word}`, 'i').test(jobListingString))).length)

  let score
  if (isExactMatch(jobListingString)) {
    // e.g. keywords and jobTitle are both 'Ruby on Rails' - ensure this gets the highest score
    score = individualKeywords.length + 2
  } else if (containsExactMatch(jobListingString)) {
    // e.g. keywords = 'Ruby on Rails' and jobTitle = 'Exciting Ruby on Rails Position
    // gets a lower score than an exact match, but higher if the keywords phrase isn't included verbatim
    score = individualKeywords.length + 1
  } else {
    // e.g. keywords = 'Ruby on Rails', jobTitle = 'Ruby / Rails Developer' - score is 2, the count of matching
    // relevant words
    score = matchingWordCount(jobListingString)
  }
  return score
}

// compare the relevanceScore of 2 jobs
export const compareJobsByRelevance = keywords => (jobA, jobB) => {
  // compare the relevanceScore of the 2 jobs, in order of field importance (jobTitle is a stronger match than
  // jobCategory, etc). The first non-zero difference is used, and no further relevanceScore calculations performed
  const getFields = ({jobTitle, jobCategory, jobDescription, prerequisitesDetails}) => (
    [jobTitle, jobCategory?.name, [jobDescription, prerequisitesDetails].join(' ')]
  )
  const jobAFields = getFields(jobA)
  const jobBFields = getFields(jobB)
  return jobAFields.reduce((relativeScore, jobAField, index) => {
    return (relativeScore
      || (relevanceScore(jobBFields[index], keywords) - relevanceScore(jobAField, keywords)))
  }, 0)
}

const compareJobsByDateField = field => (jobA, jobB) => dayjs(jobA[field]).diff(dayjs(jobB[field]))

const compareJobsBy = (sortBy, {keywords} = {}) => {
  const [field, asc = true] = sortBy.split('-').reverse()

  let comparer
  switch (field) {
    case 'relevance':
      comparer = compareJobsByRelevance(keywords)
      break
    case 'createdAt':
      comparer = compareJobsByDateField(field)
      break
    default:
      throw new Error(`unsupported sortBy ${sortBy}`)
  }

  return asc ? comparer : (jobA, jobB) => -comparer(jobA, jobB)
}

export default compareJobsBy
