import {useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import Error from '../pages/error'

// If an error has been specified in the data used for the SSR render the appropriate error page will be rendered (on
// both the server and that client as they must match). If there is no error, SsrError will render its children. If the
// user navigates to another page the SSR error will be cleared.
const SsrError = (props) => {
  const {children, error} = props
  const location = useLocation()

  const [currentError, setCurrentError] = useState(error)
  const mountedRef = useRef(false)
  useEffect(() => {
    if (mountedRef.current) {
      setCurrentError(null)
    }
    mountedRef.current = true
  }, [location])

  return currentError ? <Error type={currentError} /> : children
}

export default SsrError
