import Meta from '../../components/Meta'

const UnexpectedError = () => {
  return (
    <div className="container-lg max-width-xl py-4 px-4 px-lg-5">
      <Meta title="Error" />
      <h1>Oops! Something went wrong</h1>
      <p>Try again later. Hopefully we&apos;ll have it fixed soon.</p>
    </div>
  )
}

export default UnexpectedError
