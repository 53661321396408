import {useMemo, useCallback} from 'react'
import {useQueryString} from './QueryString'

export const useSearchParams = ({locationId, categoryId} = {}) => {
  const [queryState, setQueryState] = useQueryString()

  const searchParams = useMemo(() => {
    return {...queryState, ...(locationId && {locationId}), ...(categoryId && {categoryId})}
  }, [queryState, locationId, categoryId])

  const setSearchParams = useCallback((searchParams) => {
    // dont push when only changing sort order
    const keys = Object.keys(searchParams || {})
    const push = !(keys.length === 1 && keys[0] === 'sort')

    setQueryState((prev) => {
      const next = {
        ...prev,
        ...(locationId && {locationId}),
        ...(categoryId && {categoryId}),
        ...searchParams,
      }
      if (!next.keywords && next.sort === 'relevance') {
        next.sort = undefined
      }
      return next
    }, {push, path: '/jobs'})
  }, [setQueryState, locationId, categoryId])

  return [searchParams, setSearchParams]
}
