import {createContext, useContext, useEffect, useState} from 'react'
import {index} from './apiActions'

const LocationRepositoryContext = createContext({})
const defaultLocations = []

const LocationRepository = (props) => {
  const {children, initialData: {data: locations = defaultLocations, ...initialData} = {}} = props
  // gets initial data from props so it can be set for server-side rendering (SSR)
  const [data, setData] = useState({loading: false, ...initialData, locations})

  // pull locations once when mounted
  useEffect(() => {
    if (locations === defaultLocations) {
      index(setData, 'locations', 'locations')()
    }
  }, [locations])

  return (
    <LocationRepositoryContext.Provider value={data}>
      {children}
    </LocationRepositoryContext.Provider>
  )
}

export default LocationRepository

export const useLocationRepository = () => {
  return useContext(LocationRepositoryContext)
}
