import clsx from 'clsx'

const Slice = (props) => {
  const {children, className, variant, image, alignImage, ...other} = props
  return (
    <div
      className={clsx(className, 'slice', variant === 'alt' && 'slice--alt', image && 'slice--with-image')}
      {...other}
    >
      {children}
      {image && <SliceImage align={alignImage}>{image}</SliceImage>}
    </div>
  )
}

export const Hero = (props) => {
  const {
    className, title, children,
    imageSrc, imageXsSrc, imageSmSrc, imageMdSrc, imageLgSrc, imageXlSrc, imageXxlSrc,
    ...other
  } = props
  return (
    <div className={clsx(className, 'slice__hero')} {...other}>
      <picture>
        {imageXxlSrc && <source srcSet={imageXxlSrc} media="(min-width: 1400px)" type="image/webp" />}
        {imageXlSrc && <source srcSet={imageXlSrc} media="(min-width: 1200px)" type="image/webp" />}
        {imageLgSrc && <source srcSet={imageLgSrc} media="(min-width: 992px)" type="image/webp" />}
        {imageMdSrc && <source srcSet={imageMdSrc} media="(min-width: 768px)" type="image/webp" />}
        {imageSmSrc && <source srcSet={imageSmSrc} media="(min-width: 576px)" type="image/webp" />}
        {imageXsSrc && <source srcSet={imageXsSrc} type="image/webp" />}
        <img src={imageSrc} alt="" className="slice__hero__image" />
      </picture>
      <div className={clsx(className, 'slice__hero__content')}>
        <div className="container-lg max-width-xl px-4 px-lg-5 py-4">
          <h1 className="slice__hero__title">{title}</h1>
          {children}
        </div>
      </div>
    </div>
  )
}

export const SliceHeader = (props) => {
  const {title, className, ...other} = props
  return (
    <div className={clsx(className, 'container-lg max-width-xl px-4 px-lg-5 slice__header')} {...other}>
      <h2 className="m-0 fs-3">{title}</h2>
    </div>
  )
}

export const SliceContent = (props) => {
  const {children, className, wide, ...other} = props
  return (
    <div
      className={(
        clsx(className, 'slice__content px-4 px-lg-5', wide ? 'slice__content--wide' : 'container-lg max-width-xl')
      )}
      {...other}
    >
      {children}
    </div>
  )
}

const SliceImage = (props) => {
  const {children, className, align, ...other} = props
  return (
    <div className={clsx(className, 'slice__image', align === 'bottom' && 'slice__image--align-bottom')} {...other}>
      {children}
    </div>
  )
}

export default Slice
