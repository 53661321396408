import {snakeCase} from 'lodash-es'

import LoadTooHigh from './LoadTooHigh'
import NotFound from './NotFound'
import UnderMaintenance from './UnderMaintenance'
import UnexpectedError from './UnexpectedError'
import NoInternet from './NoInternet'

const Error = (props) => {
  const {type} = props

  switch (snakeCase(type)) {
    case 'load_too_high': return <LoadTooHigh />
    case 'not_found': return <NotFound />
    case 'service_unavailable': return <UnderMaintenance />
    case 'no_internet': return <NoInternet />
    default: return <UnexpectedError />
  }
}

export default Error
export {LoadTooHigh, NotFound, UnderMaintenance, UnexpectedError, NoInternet}
