import {Component} from 'react'
import {UnexpectedError} from '../pages/error'
import {useLocation} from 'react-router-dom'

// This is used in Layout. When an error occurs it will render the error page, instead of the default blank page.
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  componentDidUpdate(prevProps) {
    const {location} = this.props
    if (prevProps.location !== location) {
      this.setState({hasError: false})
    }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  render() {
    const {hasError} = this.state
    const {children} = this.props

    if (hasError) {
      return (
        <UnexpectedError />
      )
    }

    return children
  }
}

const withLocation = Component => function ComponentWithLocation(props) {
  const location = useLocation()
  return (
    <Component {...props} location={location} />
  )
}

export default withLocation(ErrorBoundary)
