import {compact, castArray} from 'lodash-es'

export const locationName = (locations = []) => {
  const getLocationsByIds = locationsByIds(locations)
  return id => (
    getLocationsByIds(id).map(location => (location?.name)).join(', ')
  )
}

export const locationsByIds = (locations = []) => (id) => {
  const allLocations = compact(locations.concat(
    locations.flatMap(parent => parent.areas.map(area => ({...area, parent}))),
  ))

  const locationIds = compact(castArray(id))
  return compact(locationIds.map(locationId => (
    allLocations.find(l => `${l.id}` === `${locationId}`)
  )))
}
