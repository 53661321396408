import {useCallback, useMemo, useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import {useJobRepository} from '../respositories/JobRepository'
import DetailList, {Detail} from '../components/DetailList'
import SharePageModal from '../components/SharePageModal'
import dayjs from 'dayjs'
import {PageLoader} from '../components/Loader'
import Dependent from '../components/Dependent'
import {ReactComponent as OpenInNewIcon} from 'assets/images/open-in-new.svg'
import Meta from '../components/Meta'
import {limit} from '../utils/string'
import Button from '../components/Button'
import {trackViewJob} from '../utils/tracking'

const JobListing = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {id} = useParams()

  const {jobs, actions} = useJobRepository()
  const job = useMemo(() => jobs?.find(job => `${job.id}` === `${id}`), [id, jobs])

  useEffect(() => {
    if (job) {
      trackViewJob(job)
    }
  }, [job])

  // Back will never show in a server-side render (SSR).
  // React expects the initial render on the client to match the SSR render, so we need to check if we should show the
  // back button in an effect (which will trigger an update after the first render) rather then directly in the render.
  const [showBack, setShowBack] = useState(false)
  useEffect(() => {
    setShowBack(location.key !== 'default')
  }, [location])

  const resolve = useCallback(() => actions.show(id), [actions, id])

  return (
    <Dependent on={job} resolve={resolve} loader={<PageLoader focused />}>
      {job && (
        <div className="container-lg max-width-xl pt-4 pb-5 mb-lg-5 px-4 px-lg-5">
          <Meta
            title={`${job.jobTitle} in ${job.region.name}, ${job.area.name}`}
            description={limit(job.jobDescription, 250)}
            type="article"
          />

          {showBack && (
            <div className="pt-1 pt-md-2 d-print-none">
              <Button link className="p-0 fw-bold" onClick={() => navigate(-1)}>
                &lt; Back to search results
              </Button>
            </div>
          )}
          <div className="row mb-3 pt-4">
            <div className="col-12 col-lg">
              <h1 className="mb-1">{job.jobTitle}</h1>
              <div className="fs-3 ff-alt fw-light text-secondary mb-1">Job ID {job.id}</div>
              <div className="h3 text-dark mb-1">{[job.region?.name, job.area?.name].filter(l => l).join(', ')}</div>
              <div className="h3 text-dark mb-1">{job.employmentType?.name}</div>
            </div>
            <div className="col flex-grow-0 text-nowrap fs-5 fw-extra-bold ff-alt lh-sm text-primary mb-1 pt-1 pt-lg-3">
              Listed on {dayjs(job.createdAt).format('D MMM YYYY')}
            </div>
          </div>

          <h2 className="text-dark fs-5 ff-base mb-3">About this job</h2>
          <div className="mb-4">
            {job.jobDescription?.replace(/\r\n?/g, '\n').split(/\n{2,}/)?.map((paragraph, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <p className="text-preformatted" key={i}>{paragraph}</p>
            ))}
          </div>
          <h2 className="text-dark fs-5 ff-base mb-3">Job Requirements</h2>
          <div className="mb-4">
            {job.prerequisitesDetails?.replace(/\r\n?/g, '\n').split(/\n{2,}/)?.map((paragraph, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <p className="text-preformatted" key={i}>{paragraph}</p>
            ))}
          </div>

          <DetailList className="pt-3 mb-4">
            <Detail title="Days of work">{job.daysOfWork} ({+job.hoursPerWeek} hours per week)</Detail>
            <Detail title="Pay Details">{job.payDetails}</Detail>
            <Detail title="Job Category">{job.jobCategory?.name}</Detail>
            <Detail title="Career Level">{job.careerLevel}</Detail>
            {job.drivingLicenceRequired ? (
              <>
                {job.vehicleLicence && (
                  <Detail title={job.cycleLicence ? 'Vehicle Licence' : 'Driver Licence'}>
                    <DriverLicence licence={job.vehicleLicence} />
                  </Detail>
                )}
                {job.cycleLicence && (
                  <Detail title={job.vehicleLicence ? 'Motorcycle Licence' : 'Driver Licence'}>
                    <DriverLicence licence={job.cycleLicence} />
                  </Detail>
                )}
              </>
            ) : (
              <Detail title="Driver Licence">Not Required</Detail>
            )}
            <Detail title="Pre-employment drug test required">{job.drugTestRequired ? 'Yes' : 'No'}</Detail>
          </DetailList>

          <div className="bg-light px-4 py-3">
            <h2 className="text-secondary mb-1">Interested in this job?</h2>
            <p className="fs-5 my-3">
              <strong>
                To apply for this job please phone <a href="tel:0800779009">0800 779 009</a> and quote{' '}
                <span className="text-secondary">Job ID {id}</span>
              </strong>
            </p>
            <p className="m-0">
              You may need to register first with Work and Income as a Job Seeker to be considered for specific roles.
              <br />If you are deaf, hearing impaired, or speech impaired, there are a range of options to{' '}
              <a href={'https://www.workandincome.govt.nz/about-work-and-income/contact-us/'
                    + 'deaf-hearing-impaired-and-speech-impaired-clients.html'}
                 className="fw-bold" target="_blank" rel="noopener">
                contact us
                <OpenInNewIcon className="ms-1 align-baseline" />
              </a>
              . Please include the Job IDs for any of the jobs you&apos;re interested in as part of the message.
            </p>
          </div>

          <div className="d-md-flex align-items-center pt-3 d-print-none">
            <SharePageModal>
              <Button rounded color="primary" className="fw-bold px-4 my-3">
                Share this job
              </Button>
            </SharePageModal>
            <div className="my-2 py-1 px-1 px-md-5">
              <h2 className="fs-5 ff-base text-dark m-0">Something wrong with this job ad?</h2>
              <a href={`${import.meta.env.PUBLIC_FORMS_BASE_URL}/report_find_a_job_ads/new?job_id=${job.id}&job_title=${job.jobTitle}`}
                 className="fw-bold" target="_blank" rel="noopener">
                Report this job ad
                <OpenInNewIcon className="ms-2 align-baseline" />
              </a>
            </div>
          </div>
        </div>
      )}
    </Dependent>
  )
}

const isComplexLicence = (licence) => {
  return licence?.licenceTypes?.length > 1 || licence?.licenceClasses?.length > 1
}

const DriverLicence = (props) => {
  const {licence, ...other} = props
  const {licenceTypes, licenceClasses, endorsements} = licence

  const list = (collection, format) => collection.map(item => format(item)).join(', ')

  const details = [
    list(licenceTypes, ({description}) => description),
    list(licenceClasses, ({code, description}) => `(${code}) - ${description}`),
    list(endorsements, ({code, description}) => `(${code}) - ${description}`),
  ].filter(p => p)

  return (
    <div {...other}>
      <div className="lh-1 py-1" key={licenceClasses.join}>
        {isComplexLicence(licence) ? (
          details.map(list => (
            <div className="mb-2" key={list}>
              {list}
            </div>
          ))
        ) : (
          details.join(', ')
        )}
      </div>
    </div>
  )
}

export default JobListing
