// customizers for lodash isEqualWith
import {isBlank} from './presence'

export const looseCustomizer = (val1, val2) => {
  const values = [val1, val2]
  if (values.some(v => typeof v === 'string') && values.some(v => typeof v === 'number')) {
    return `${values[0]}` === `${values[1]}`
  }
  if (values.every(isBlank)) {
    return true
  }
}
