import {createContext, useContext, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {upperFirst} from 'lodash-es'

const FormContext = createContext({})

// We want to set the value and handle the onChange event on input elements to set their values to the current state and
// update the state when the value changes. However, this requires setting the value and onChange events on every input.
// see: https://reactjs.org/docs/forms.html

// Controlled form sets Inputs' values and handles onChange events based on their name attribute. It provides a single
// onChange event and data attribute at the form level rather than setting onChange and value at an input level
const ControlledForm = (props) => {
  const {data, onChange, errors, onSubmit, children, ...other} = props
  // useMemo will return the same [data || {}, onChange] array instance each render until data or onChange changes
  const contextValue = useMemo(() => [data || {}, onChange, errors || {}], [data, onChange, errors])

  const submit = useCallback((event) => {
    event.preventDefault()
    onSubmit && onSubmit(event, data)
  }, [onSubmit, data])

  // pass contextValue down to descendents via FormContext
  return (
    <FormContext.Provider value={contextValue}>
      <form onSubmit={submit} {...other}>
        {children}
      </form>
    </FormContext.Provider>
  )
}

export default ControlledForm

export const Input = (props) => {
  const {component: Component = 'input', className, name, ...other} = props
  // get value from nearest ancestor FormContext - provided by ControlledForm
  const [data, setData, errors] = useContext(FormContext)

  const hasErrors = !!errors[name]?.length

  const change = useCallback((event) => {
    const {name, value} = event.target
    setData({[name]: value === '' ? undefined : value})
  }, [setData])

  // The component rendered here can be set to any html element (lowercase string)
  // or react component (function or class) using the component prop
  return (
    <>
      <Component className={clsx(hasErrors && 'is-invalid', className)} value={data[name] || ''}
                 name={name} onChange={change} {...other} />
      <ErrorMessage name={name} className="pt-1" />
    </>

  )
}

export const ErrorMessage = (props) => {
  const {component: Component = 'div', className, name, ...other} = props
  // get value from nearest ancestor FormContext - provided by ControlledForm
  const [, , errors] = useContext(FormContext)

  let myErrors = errors[name]
  if (typeof myErrors === 'string') {
    myErrors = [myErrors]
  }

  const errorMessage = myErrors && upperFirst(myErrors.join(', '))

  return (
    <Component className={clsx('error-message', errorMessage && className)} {...other} aria-live="polite">
      {errorMessage}
    </Component>
  )
}
