import {BrowserRouter} from 'react-router-dom'
import {StaticRouter} from 'react-router-dom/server'

// Uses BrowserRouter when running on client (browser).
// When server-side rendering (SSR) a specific path will be passed so it uses StaticRouter to render that path
const Router = (props) => {
  const {location} = props

  if (location !== undefined) {
    return <StaticRouter location={location} {...props} />
  }
  return <BrowserRouter {...props} />
}

export default Router
