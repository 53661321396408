import {useCallback} from 'react'
import {trackButton} from '../utils/tracking'
import clsx from 'clsx'

const Button = (props) => {
  const {children, className, submit, color, link, rounded, onClick, ...other} = props
  const click = useCallback((event) => {
    onClick && onClick(event)
    trackButton({name: event.target.innerText})
  }, [onClick])

  const classes = clsx(
    className,
    'btn',
    color === 'primary' && 'btn-primary',
    color === 'secondary' && 'btn-secondary',
    color === 'success' && 'btn-success',
    color === 'danger' && 'btn-danger',
    color === 'warning' && 'btn-warning',
    color === 'info' && 'btn-info',
    color === 'light' && 'btn-light',
    color === 'dark' && 'btn-dark',
    link && 'btn-link',
    rounded && 'rounded-pill',
  )

  return (
    <button
      className={classes}
      type={submit ? 'submit' : 'button'}
      onClick={click}
      {...other}
    >
      {children}
    </button>
  )
}

export default Button
