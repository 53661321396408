import {get, getApiErrorData} from '../utils/api'
import {useRef, useCallback} from 'react'

export const show = (setData, resourcePath, resourceName) => async (id, signal) => {
  setData(prev => ({...prev, loading: true, error: null, request: {id}}))
  try {
    const {data, ...other} = await get(`${resourcePath}/${id}`, undefined, signal)
    setData(prev => ({...prev, [resourceName]: [data], error: null, ...other, loading: false}))
    return {data, ...other}
  } catch (e) {
    setData(prev => ({...prev, ...getApiErrorData(e), loading: false}))
    throw e
  }
}

export const index = (setData, resourcePath, resourceName, baseParams) => async (params, signal) => {
  setData(prev => ({...prev, loading: true, error: null, request: params}))
  try {
    const {data, ...other} = await get(resourcePath, {...baseParams, ...params}, signal)
    setData(prev => ({
      ...prev, [resourceName]: data, error: null, ...other, loading: false, pageSize: other.meta?.limit,
    }))
    return {data, ...other}
  } catch (e) {
    if (!(e instanceof DOMException)) {
      // DOMException is raised when a request is aborted. We don't want to handle these
      setData(prev => ({...prev, ...getApiErrorData(e), loading: false}))
    }
    throw e
  }
}

// returns a function that aborts any other in-progress abortable action before calling the provided action.
// ref is set to a new abort controller and the abort signal is passed as the last parameter to the action.
// abort signal should be passed to fetch by the action so it can be canceled
export const abortable = (ref, action) => (...args) => {
  ref.current && ref.current.abort()
  // eslint-disable-next-line no-param-reassign
  ref.current = new AbortController()
  return action(...args, ref.current.signal)
}

export const useAbortable = () => {
  const ref = useRef()

  return useCallback(action => abortable(ref, action), [])
}
