import Router from './components/Router'
import Routes from './Routes'
import Layout from './components/Layout'
import SsrError from './components/SsrError'
import JobRepository from './respositories/JobRepository'
import LocationRepository from './respositories/LocationRepository'
import JobCategoryRepository from './respositories/JobCategoryRepository'
import {SessionStorageProvider} from './utils/SessionStorage'

const App = (props) => {
  // path and initial data can be passed via props to support server-side rendering (SSR)
  const {path, data: {locations, jobs, jobCategories, error} = {}} = props

  return (
    <JobRepository initialData={jobs}>
      <LocationRepository initialData={locations}>
        <JobCategoryRepository initialData={jobCategories}>
          <SessionStorageProvider>
            <Router location={path}>
              <Layout>
                <SsrError error={error}>
                  <Routes />
                </SsrError>
              </Layout>
            </Router>
          </SessionStorageProvider>
        </JobCategoryRepository>
      </LocationRepository>
    </JobRepository>
  )
}

export default App
