import TeReo from '../components/TeReo'
import Meta from '../components/Meta'
import Slice, {Hero, SliceHeader, SliceContent} from '../components/Slice'
import InfoBox from '../components/InfoBox'
import heroImageUrl from 'assets/images/how-it-works/hero.jpg'
import heroImageXsUrl from 'assets/images/how-it-works/hero-mobile.webp'
import heroImageSmUrl from 'assets/images/how-it-works/hero-tablet.webp'
import heroImageXlUrl from 'assets/images/how-it-works/hero.webp'
import winzLetterImageUrl from 'assets/images/how-it-works/winz-letter.svg'
import callUsImageUrl from 'assets/images/how-it-works/call-us-image.jpg'
import refineImageUrl from 'assets/images/how-it-works/refine-on-phone.png'
import refineImageWebpUrl from 'assets/images/how-it-works/refine-on-phone.webp'
import searchImageUrl from 'assets/images/how-it-works/search-on-phone.png'
import searchImageWebpUrl from 'assets/images/how-it-works/search-on-phone.webp'
import {ReactComponent as HearingDisabledIcon} from 'assets/images/hearing-disabled-icon.svg'

const HowItWorks = () => {
  return (
    <div className="pb-5 mb-lg-3">
      <Meta
        title="How It Works"
        description="Support to use the Find a Job website and apply for jobs through MyMSD."
        type="article"
      />

      <Slice>
        <Hero
          imageSrc={heroImageUrl}
          imageXsSrc={heroImageXsUrl}
          imageSmSrc={heroImageSmUrl}
          imageXlSrc={heroImageXlUrl}
          title={(
            <>
              <TeReo>Pēhea te Whakamahi</TeReo>
              <br />
              How It Works
            </>
          )}
        />
      </Slice>
      <Slice>
        <SliceContent>
          <div className="row">
            <div className="col-12 col-xxl-6 align-self-center">
              <p className="fs-3">
                Find out how to use <TeReo>Kimi Mahi Mai</TeReo> Find a Job to find and apply for jobs
              </p>
              <p className="pb-4">
                We lists hundreds of jobs from employers we work with
              </p>
            </div>
            <div className="col-12 col-xxl-6">
              <InfoBox className="how-it-works__right-to-work-info">
                <p>
                  To apply for jobs on this site, you must have the right to work in New Zealand. This means you’re
                  either:
                </p>
                <ul>
                  <li>a New Zealand or Australian Citizen,</li>
                  <li>a New Zealand Resident, or</li>
                  <li>hold a visa which allows you to work in New Zealand.</li>
                </ul>
                <p>
                  If you have a working holiday visa, check out the seasonal jobs on
                  the <a href="https://www.opportunitygrowshere.nz/">Opportunity grows here</a> website.
                  It covers a range of different industries so you can find the one that best suits you.
                </p>
              </InfoBox>
            </div>
          </div>
        </SliceContent>
      </Slice>
      <Slice variant="alt">
        <SliceHeader title="Step 1 - Search for jobs" />
        <SliceContent wide>
          <div className="how-it-works__search-instructions">
            <div className="row g-5">
              <div className="col-12 col-lg-6">
                <div className="d-flex flex-column-reverse flex-sm-row mx-n3">
                  <picture>
                    <source srcSet={searchImageWebpUrl} type="image/webp" />
                    <source srcSet={searchImageUrl} type="image/png" />
                    <img src={searchImageUrl} alt="Phone showing search box" className="mx-3 how-it-works__phone" />
                  </picture>
                  <div className="mx-3 pt-md-4">
                    <h3 className="fs-5 ff-base">To search for a job</h3>
                    <ol>
                      <li>Use the dropdown arrow in the ‘Location’ field to choose a location (city)</li>
                      <li>Enter a keyword in the ‘Keywords’ field</li>
                      <li>Use the dropdown arrow in the ‘Category’ field to choose a category</li>
                      <li>Select ‘Search’</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="d-flex flex-column-reverse flex-sm-row-reverse flex-lg-row mx-n3">
                  <picture>
                    <source srcSet={refineImageWebpUrl} type="image/webp" />
                    <source srcSet={refineImageUrl} type="image/png" />
                    <img src={refineImageUrl} alt="Phone showing search results" className="mx-3 how-it-works__phone" />
                  </picture>
                  <div className="mx-3 pt-md-4">
                    <h3 className="fs-5 ff-base">Refine your results</h3>
                    <ol>
                      <li>Filter by work type – select ‘Full time’ or ‘Part time’.</li>
                      <li>
                        Use the dropdown arrow to sort by:
                        <ul>
                          <li>Relevance (only available for Keyword searches), or</li>
                          <li>Date listed: newest to oldest, or</li>
                          <li>Date listed: oldest to newest.</li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </SliceContent>
      </Slice>
      <Slice
        image={
          <img src={winzLetterImageUrl} alt="A letter showing the position of the client number at the top right" />
        }
        alignImage="bottom"
      >
        <SliceHeader title="Step 2 – Get a Work and Income client number" />
        <SliceContent>
          <p>
            You need a Work and Income client number to apply for these jobs. This is a 9-digit number, e.g.
            000-000-000. You can find this in letters from Work and Income, or on your Community Services Card. You
            don&apos;t need to be getting a benefit to have a client number.
          </p>
          <p>
            If you don&apos;t have a client number, you can request one from Work and Income.
          </p>
          <p>
            <a href="https://workandincome.govt.nz/online-services/swn/request-client-number.html">
              How to get a client number
            </a>
          </p>
        </SliceContent>
      </Slice>
      <Slice
        variant="alt"
        image={<img src={callUsImageUrl} alt="" className="rounded-circle" width={307} height={308} />}
      >
        <SliceHeader title="Step 3 – Call us and tell us the Job ID" />
        <SliceContent>
          <p>
            Once you have your client number and you’ve found a job you like, call us
            on <a href="tel:0800779009" className="text-nowrap">0800 779 009</a>. You need to tell us the Job IDs for
            any of the jobs you’re interested in. You can find the Job ID at the top of the page about the job, below
            the job title.
          </p>
          <InfoBox title="Deaf, hard of hearing or have a speech impairment" icon={<HearingDisabledIcon />}>
            If you are deaf, or have difficulty hearing or speaking, there are a range of options to {' '}
            {/* eslint-disable-next-line max-len */}
            <a href="https://www.workandincome.govt.nz/about-work-and-income/contact-us/deaf-hearing-impaired-and-speech-impaired-clients.html">contact us</a>.
            Please include the Job IDs for any of the jobs you’re interested in as part of the message.
          </InfoBox>
        </SliceContent>
      </Slice>
      <Slice>
        <SliceHeader title="Need Help?" />
        <SliceContent>
          <p>
            Find out how to <a href="/contact">contact us</a>. Our team is happy to help.
          </p>
        </SliceContent>
      </Slice>
    </div>
  )
}

export default HowItWorks
