import {useCallback, useMemo} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import qs from 'qs'

export const useQueryString = () => {
  const {search: queryString, pathname} = useLocation()
  const navigate = useNavigate()

  // useMemo return the same instance of the state object unless queryString changes
  const state = useMemo(() => {
    return qs.parse(queryString.slice(1))
  }, [queryString])

  // useCallback returns the same instance of the setState function unless state changes
  const setState = useCallback((newState, {push, path = pathname} = {}) => {
    const updatedState = typeof newState === 'function' ? newState(state) : newState
    // need to use arrayFormat: 'brackets' to match the format rails expects so we can use qs for server-side rendering
    navigate(`${path}?${qs.stringify(updatedState, {arrayFormat: 'brackets'})}`, {replace: !push})
  }, [state, navigate, pathname])

  return [state, setState]
}
