import {compact, castArray} from 'lodash-es'

export const jobCategoryName = (jobCategories = []) => {
  const getJobCategoriesByIds = jobCategoriesByIds(jobCategories)
  return id => (
    getJobCategoriesByIds(id).map(jobCategory => (jobCategory?.name)).join(', ')
  )
}

export const jobCategoriesByIds = (jobCategories = []) => (id) => {
  const allJobCategories = compact(jobCategories.concat(
    jobCategories.flatMap(parent => parent.subCategories.map(category => ({...category, parent}))),
  ))

  const jobCategoryIds = compact(castArray(id))
  return compact(jobCategoryIds.map(jobCategoryId => (
    allJobCategories.find(l => `${l.id}` === `${jobCategoryId}`)
  )))
}
