import {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import Link from './Link'
import msdLogoUrl from 'assets/images/msd-logo-white.png'
import nzGovtLogoUrl from 'assets/images/nzgovt-logo-white.svg'
import winzLogoUrl from 'assets/images/winz-logo-white.svg'
import ErrorBoundary from './ErrorBoundary'
import TeReo from './TeReo'
import Meta from './Meta'

const Layout = (props) => {
  const {children} = props
  const hamburgerRef = useRef()

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (event.target !== hamburgerRef.current && !hamburgerRef.current.classList.contains('collapsed')) {
        hamburgerRef.current.click()
      }
    }
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  return (
    <>
      <Meta />
      <header className="navbar main-nav navbar-dark navbar-expand-xl bg-primary bg-print-white p-0">
        <a href="#main-content" className="skip-navigation">Skip to main content</a>
        <div className="container-fluid py-3 py-xl-4 px-3 px-sm-4 px-lg-5">
          <div className="focus-indicator-light p-1 m-n1">
            <Link className="navbar-brand main-nav__logo text-print-primary p-0 mx-lg-auto mx-0 ff-alt fw-extra-bold"
                  to="/">
              <TeReo>Kimi Mahi Mai</TeReo> Find a Job
            </Link>
          </div>
          <button className="navbar-toggler main-nav__hamburger border-0 text-white collapsed d-print-none"
                  type="button" ref={hamburgerRef} data-bs-toggle="collapse" data-bs-target="#main-links"
                  aria-controls="main-links" aria-expanded="false" aria-label="Toggle navigation">
            <span />
          </button>
          <nav className="main-nav__item-container collapse navbar-collapse d-print-none ps-xl-5" id="main-links">
            <ul className="navbar-nav main-nav__items fw-bold ms-auto py-4 py-xl-0">
              <NavItem to="/" match={['/jobs', '/locations', '/categories']}>
                <TeReo>Kimitanga Mahi</TeReo> Job Search
              </NavItem>
              <NavItem to="/help">
                <TeReo>Pēhea te Whakamahi</TeReo> How it Works
              </NavItem>
              <NavItem to="/contact">
                <TeReo>Whakapā Mai</TeReo> Contact Us
              </NavItem>
            </ul>
          </nav>
        </div>
      </header>
      <main id="main-content" className="main-content" tabIndex="-1">
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
      </main>
      <footer className="bg-primary text-white d-print-none">
        <div className="container-lg px-4 px-lg-5 px-xl-4 py-2">
          <div className="py-4">
            <h2 className="text-white py-2 m-0 fs-4 ff-base">
              <TeReo className="d-block d-sm-inline">Ngā Hononga Āwhina</TeReo> Helpful Links
            </h2>
            <div className="py-1">
              <ul className="fw-bold footer-links">
                <li>
                  <a className="text-white focus-indicator-light p-1 m-n1 d-inline-block"
                     href="https://www.careers.govt.nz/">
                    careers.govt.nz
                  </a>
                </li>
                <li>
                  <a className="text-white focus-indicator-light p-1 m-n1 d-inline-block"
                     href="https://workandincome.govt.nz/work/get-ready-to-work/cvs-and-cover-letters/index.html">
                    CV and Interview tips
                  </a>
                </li>
                <li>
                  <a className="text-white focus-indicator-light p-1 m-n1 d-inline-block"
                     /* eslint-disable-next-line max-len */
                     href="https://www.workandincome.govt.nz/form/workandincome/govt/nz/form.req2?requestType=workandincome-govt-nz-vacancy-form-v1">
                    List a job vacancy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="py-4">
            <div className="row gx-5 gy-4 align-items-center">
              <div className="col-12 col-sm-4 flex">
                <div className="focus-indicator-light p-1 m-n1 d-inline-block">
                  <a className="text-white focus-indicator-light p-1 m-n1 d-inline-block"
                     href="https://www.msd.govt.nz/">
                    <img className="img-fluid py-1" src={msdLogoUrl} width={300} height={69}
                         alt="Ministry of Social Development – Te Manatū Whakahiato Ora" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-sm-4 text-sm-center">
                <div className="focus-indicator-light p-1 m-n1 d-inline-block">
                  <a className="text-white focus-indicator-light p-1 m-n1 d-inline-block"
                     href="https://www.workandincome.govt.nz">
                    <img className="img-fluid py-1" src={winzLogoUrl} width={300} height={69}
                         alt="Work and Income – Te Hiranga Tangata" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-sm-4 text-sm-end pb-4 pb-sm-0">
                <div className="focus-indicator-light p-1 m-n1 d-inline-block">
                  <a className="text-white focus-indicator-light p-1 m-n1 d-inline-block"
                     href="https://www.govt.nz/">
                    <img className="img-fluid py-1" src={nzGovtLogoUrl}
                         alt="New Zealand Government" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ul className="py-4 border-top border-white lh-1 footer-links">
            <li>
              <a href="https://workandincome.govt.nz/about-this-site/privacy-disclaimer-and-copyright-information.html"
                 className="text-white text-legal focus-indicator-light p-1 m-n1">
                Privacy, disclaimer and copyright
              </a>
            </li>
            <li>
              <Link to="/accessibility" className="text-white text-legal focus-indicator-light p-1 m-n1">
                Accessibility Statement
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </>
  )
}

const NavItem = (props) => {
  const {to, match, children} = props
  const location = useLocation()
  const active = to === location.pathname || match?.some(path => location.pathname.startsWith(path))
  const classList = `nav-link p-0 text-white text-uppercase main-nav__link ${active && 'main-nav__link--active'}`
  const isExternal = to.includes('://')

  return (
    <li className="nav-item main-nav__item py-2 py-xl-0">
      <div className="focus-indicator-light px-1 mx-n1">
        {isExternal
          ? (
            <a href={to} className={classList}>
              {children}
            </a>
          )
          : (
            <Link
            to={to}
            className={classList}
          >
              {children}
            </Link>
          )}
      </div>
    </li>
  )
}

export default Layout
