import {useContext, createContext, useMemo} from 'react'

const ButtonSelectContext = createContext({})

const ButtonSelect = (props) => {
  const {children, name, label, value, onChange, ...other} = props
  const context = useMemo(() => ({name, selectedValue: value, onChange}), [name, value, onChange])

  // pass name, value and onChange down to child OptionButtons via ButtonSelectContext
  return (
    <ButtonSelectContext.Provider value={context}>
      <fieldset {...other}>
        {label && <legend className="form-label fs-5 text-primary fw-bold">{label}</legend>}

        <div className="btn-group btn-group-sm filter-buttons">
          {children}
        </div>
      </fieldset>
    </ButtonSelectContext.Provider>
  )
}

export default ButtonSelect

export const OptionButton = (props) => {
  // get name, selectedValue and onChange from nearest ancestor ButtonSelectContext - provided by ButtonSelect
  const {name, selectedValue, onChange} = useContext(ButtonSelectContext)
  const {value, id = `${name}-${value}`, children, ...other} = props

  const checked = `${value || ''}` === `${selectedValue || ''}`

  return (
    <>
      <input type="radio" className="btn-check" name="workType" id={id}
             value={value} checked={checked} onChange={onChange} />
      <label className="btn btn-outline-primary" htmlFor={id} {...other}>{children}</label>
    </>
  )
}
