import {useEffect, useRef, useCallback} from 'react'
import {useLocationRepository} from '../respositories/LocationRepository'
import {locationName} from './locations'
import {useJobCategoryRepository} from '../respositories/JobCategoryRepository'
import {jobCategoryName} from './jobCategories'

export const track = (event, parameters) => {
  window.gtag && window.gtag('event', event, parameters)
}

export const trackInternalLink = ({name, url}) => {
  track('internal_link_click', {link_name: name, link_url: url})
}

export const trackButton = ({name}) => {
  track('button_click', {button_name: name})
}

export const trackSearch = ({
  type, locationId, locationName, keywords, categoryId, categoryName, workType, sort,
}) => {
  track('job_search', {
    search_type: type,
    search_location_id: locationId,
    search_location_name: locationName,
    search_keywords: keywords,
    search_category_id: categoryId,
    search_category_name: categoryName,
    search_work_type: workType,
    search_sort: sort,
  })
}

export const trackShowMore = ({prevPageSize, newPageSize, totalCount, incompleteSet}) => {
  track('show_more', {
    show_previous_page_size: Math.min(prevPageSize, totalCount),
    show_new_page_size: Math.min(newPageSize, totalCount),
    show_total_count: totalCount,
    show_all: newPageSize >= totalCount,
    limit_met: newPageSize >= totalCount && incompleteSet,
  })
}

export const trackNotFound = () => {
  track('not_found', {})
}

export const trackMaintenance = () => {
  track('under_maintenance', {})
}

export const trackLoadTooHigh = () => {
  track('load_too_high', {})
}

export const trackViewJob = ({id, jobTitle}) => {
  track('view_job', {job_id: id, job_title: jobTitle})
}

export const trackValidationError = ({resource, action, error}) => {
  track('validation_error', {
    error_resource: resource,
    error_action: action,
    error_text: Object.entries(error?.meta || {}).map(([key, value]) => `${key} ${value.join(', ')}`).join('; '),
  })
}

export const useSearchTracking = ({searchParams}) => {
  const {locations} = useLocationRepository()
  const {jobCategories} = useJobCategoryRepository()
  const getLocationName = useCallback(id => locationName(locations)(id), [locations])
  const getCategoryName = useCallback(id => jobCategoryName(jobCategories)(id), [jobCategories])

  const initialRef = useRef(true)
  useEffect(() => {
    const {locationId, categoryId} = searchParams
    trackSearch({
      ...searchParams,
      locationName: getLocationName(locationId),
      categoryName: getCategoryName(categoryId),
      type: initialRef.current ? 'new' : 'update',
    })
    initialRef.current = false
  }, [searchParams, getLocationName, getCategoryName])
}

export const usePageSizeTracking = ({pageSize, count, incomplete}) => {
  const prevPageSize = useRef(pageSize)
  useEffect(() => {
    if (pageSize > prevPageSize.current) {
      trackShowMore({
        prevPageSize,
        newPageSize: pageSize,
        totalCount: count,
        incompleteSet: incomplete,
      })
    }
  }, [pageSize, count, incomplete])
}
