import qs from 'qs'
import {castArray} from 'lodash-es'

const BASE_URL = '/api'

export const get = async (url, params, signal) => {
  return await call(
    'GET',
    `${url}${params ? '?' : ''}${qs.stringify(params, {arrayFormat: 'brackets'})}`,
    undefined,
    signal,
  )
}

export const call = async (method, url, body, signal) => {
  const response = await fetch(`${BASE_URL}/${url}`, {
    method,
    signal,
    headers: {
      Accept: 'application/json',
      ...(body && {'Content-Type': 'application/json'}),
    },
    ...(body && {body: JSON.stringify(body)}),
  })
  if (!response.ok) {
    throw new FetchError(response.status, await response.json())
  }
  return await response.json()
}

export class FetchError extends Error {
  constructor(status, data, message) {
    super(message)
    this.status = status
    this.data = data
  }
}

export const handleFetchError = ({only, except} = {}) => (error) => {
  if (error instanceof DOMException) return // handle aborted requests

  const {error: {type}} = getApiErrorData(error)
  if ((!only || castArray(only).includes(type)) && (!except || !castArray(except).includes(type))) return

  throw error
}

export const getApiErrorData = (error) => {
  if (error instanceof FetchError) {
    return error.data
  }
  if (error instanceof TypeError) {
    // see fetch exceptions here: https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
    return {error: {type: 'NO_INTERNET', message: 'could not connect to the server'}}
  }
  return {error: {type: 'UNEXPECTED_ERROR', message: 'unexpected error'}}
}
