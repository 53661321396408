import {isEmpty, isObjectLike, isArray} from 'lodash-es'

export const isBlank = value => (!value && value !== 0) || (isObjectLike(value) && isEmpty(value))
export const isPresent = value => !isBlank(value)

export const count = (value) => {
  if (isBlank(value)) {
    return 0
  }
  if (isArray(value)) {
    return value.filter(isPresent).length
  }
  return 1
}
