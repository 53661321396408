import clsx from 'clsx'
import {ReactComponent as InfoIcon} from 'assets/images/info-icon.svg'

const InfoBox = (props) => {
  const {children, title, icon, className, ...other} = props
  return (
    <div {...other} className={clsx(className, 'info-box')}>
      <div>
        {icon || <InfoIcon />}
      </div>
      <div className="info-box__content">
        {title && <h3 className="fs-5 ff-base">{title}</h3>}
        {children}
      </div>
    </div>
  )
}

export default InfoBox
