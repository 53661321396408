export const limit = (string, length) => {
  if (!string || string.length <= length) {
    return string
  }

  const words = string.split(' ')
  let result = words[0]
  for (let i = 1; i < words.length; i += 1) {
    if (result.length + words[i].length + 1 >= length) {
      break
    }
    result += ` ${words[i]}`
  }
  return `${result}…`
}
